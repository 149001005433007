import React from 'react';

export const Avatar = ({ className = '', children }) => {
  return (
    <div className={`relative inline-block ${className}`}>
      {children}
    </div>
  );
};

export const AvatarImage = ({ src, alt }) => {
  return (
    <img
      src={src}
      alt={alt}
      className="w-full h-full object-cover rounded-full"
      onError={(e) => {
        e.currentTarget.style.display = 'none';
      }}
    />
  );
};

export const AvatarFallback = ({ children }) => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-gray-700 text-gray-200 rounded-full text-lg font-semibold">
      {children}
    </div>
  );
};