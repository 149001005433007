import React, {Component} from 'react';
import MobileTearSheet from '../components/common/MobileTearSheet';
import {List, ListItem} from 'material-ui/List';
import Divider from 'material-ui/Divider';
import Subheader from 'material-ui/Subheader';
import {darkBlack, blue900} from 'material-ui/styles/colors';

import {getCurrentDate} from '../utils/utils';

class UpComingCalendarContainer extends Component {
  render() {
    return (
      <MobileTearSheet height={400}>
        <List>
          <Subheader style={{color: blue900}}>My Calendar</Subheader>
          <Subheader>Today ({getCurrentDate()})</Subheader>
          <ListItem
            leftAvatar={<i style={{fontSize:'2em'}} className="fas fa-calendar-alt"></i>}
            primaryText="Brendan Lim"
            secondaryText={
                            <p>
                            <span style={{color: darkBlack}}>Brunch this weekend?</span><br />
                            I&apos;ll be in your neighborhood doing errands this weekend. Do you want to grab brunch?
                            </p>
                        }
            secondaryTextLines={2}
          />
          <Divider inset={true}/>
          <ListItem
            leftAvatar={<i style={{fontSize:'2em'}} className="fas fa-calendar-alt"></i>}
            primaryText="me, Scott, Jennifer"
            secondaryText={
                            <p>
                            <span style={{color: darkBlack}}>Summer BBQ</span><br />
                            Wish I could come, but I&apos;m out of town this weekend.
                            </p>
                        }
            secondaryTextLines={2}
          />
          <Divider inset={true}/>
          <ListItem
            leftAvatar={<i style={{fontSize:'2em'}} className="fas fa-calendar-alt"></i>}
            primaryText="Grace Ng"
            secondaryText={
                            <p>
                            <span style={{color: darkBlack}}>Oui oui</span><br />
                            Do you have any Paris recs? Have you ever been?
                            </p>
                        }
            secondaryTextLines={2}
          />
        </List>
      </MobileTearSheet>
    )
  }
}

export default UpComingCalendarContainer;