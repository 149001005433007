import { callApi } from './utils'

// Function to upload profile image

const profileImageUpload = async (formData, queryParams) => {
  try {
    const headers = {
      // 'Content-Type': 'multipart/form-data's
    };
    const response = await callApi(`/api/v1/postImage?${queryParams}`,'post',formData, headers);
    return response;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export { profileImageUpload };