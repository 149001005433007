import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import AfterAuth from './AfterAuth';
import LoginContainer from '../../containers/LoginContainer';

import SignUpContainer from '../../containers/SignUpContainer';
import ProfileContainer from '../../containers/ProfileContainer';
import CommunityContainer from '../../containers/CommunityContainer';
// import ArtistHomeContainer from '../../containers/ArtistHomeContainer';
import InsightsContainer from '../../containers/InsightsContainer';
import BookingContainer from '../../containers/BookingContainer';
import BookingHomeContainer from '../../containers/BookingHomeContainer';
import SettingsContainerV2 from '../../containers/SettingsContainerV2';
// import SettingsPage from '../pages/settings/SettingsPage'
import SocialContainer from '../../containers/SocialContainer';
import AdminContainer from '../../containers/AdminContainer';
import DashboardContainer from '../../containers/DashboardContainer';
import CalendarContainer from '../../containers/CalendarContainer';
import KnktCalendarContainer from '../../containers/KnktCalendarContainer';
import CatalogContainer from '../../containers/CatalogContainer';
import PromoteContainer from '../../containers/PromoteContainer';
import ScoreContainer from '../../containers/ScoreContainer';
import TourContainer from '../../containers/TourContainer';
import PromoLinkContainer from '../../containers/PromoLinkContainer';
import InstagramInsights from '../pages/insights/social/InstagramInsights';
import FacebookInsights from '../pages/insights/social/FacebookInsights';
import FacebookVideoInsights from '../pages/insights/social/FacebookVideoInsights';

import HomePage from '../pages/HomePage';
import ConnectionContainer from '../../containers/ConnectionContainer';
import ConnectionInstagramContainer from '../../containers/ConnectionInstagramContainer';
import FansHome from '../../account/fans';
import OauthHandler from '../common/OauthHandler';
import PrivacyAndTermsPage from './PrivacyAndTermsPage';
import ForgotPasswordContainer from '../../containers/ForgotPasswordContainer';
import ResetPasswordContainer from '../../containers/ResetPasswordContainer';
import CreateLinkContainer from '../../containers/CreateLinkContainer';
import AllowToManageContainer from '../../containers/AllowToManageContainer';

import {
  verifyToken,
  logoutUserAction,
} from '../../actions/Login/loginAction';

const styles = {
  wrapper: {
    minHeight: 'calc(100vh - 70px)',
  },
};

class BasicPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
    };
  }

  componentDidMount() {
    this.verifyToken();
  }

  verifyToken() {
    this.props.verifyToken();
  }

  render() {
    // console.log('----this.props.isLogin:', (this.props.user && this.props.user.knktId !== undefined));
    
    const { user, location } = this.props;
    // console.log("router props",user, location)

    return (
      <React.Fragment>
        {user && user.knktId ? (
          <AfterAuth
            user={this.props.user}
            handleLogout={this.props.logoutUserAction}
            verifyToken={this.props.verifyToken}
          >
            <div style={styles.wrapper}>
              <Route
                key="1.1"
                path="/dashboard"
                render={() => <DashboardContainer {...this.props} />}
              />
              <Route
                key="1.2"
                path="/insightx"
                render={() => <InsightsContainer {...this.props} />}
              />
              <Route
                key="1.3"
                path="/promolink"
                component={PromoLinkContainer}
              />
              <Route
                key="1.27"
                path="/createlink"
                render={() => <CreateLinkContainer user={user} />}
              />
              <Route
                key="1.4"
                path="/profile"
                render={() => <ProfileContainer user={user} location={location}/>}
              />
              <Route
                key="1.5"
                path="/settings"
                render={() => <SettingsContainerV2 {...this.props} />}
              />
              <Route
                key="1.6"
                exact
                path="/insights"
                render={() => <DashboardContainer {...this.props} />}
              />
              <Route
                key="1.6.1"
                path="/insights/fb"
                component={FacebookInsights}
              />
              <Route
                key="1.6.1.1"
                path="/insights/fbvideo"
                component={FacebookVideoInsights}
              />
              <Route
                key="1.6.2"
                path="/insights/instagram"
                component={InstagramInsights}
              />
              <Route
                key="1.21"
                path="/connectInstagram"
                component={ConnectionInstagramContainer}
              />
              <Route
                key="1.7"
                exact
                path="/connection"
                render={() => <ConnectionContainer {...this.props} />}
              />
              <Route
                key="1.8"
                path="/booking"
                render={() => <BookingContainer {...this.props} />}
              />
              <Route
                key="1.81"
                path="/bookinghome"
                render={() => (
                  <BookingHomeContainer {...this.props} />
                )}
              />
              <Route
                key="1.18"
                exact
                path="/calendar/:artistId"
                component={CalendarContainer}
              />
              <Route
                key="1.20"
                exact
                path="/calendar"
                component={CalendarContainer}
              />
              <Route
                key="1.22"
                path="/knktcalendar"
                render={() => (
                  <KnktCalendarContainer {...this.props} />
                )}
              />
              <Route
                key="1.23"
                path="/catalog"
                render={() => <CatalogContainer {...this.props} />}
              />
              <Route
                key="1.24"
                path="/promote"
                render={() => <PromoteContainer {...this.props} />}
              />
              <Route
                key="1.25"
                path="/score"
                render={() => <ScoreContainer {...this.props} />}
              />
              <Route
                key="1.26"
                path="/community"
                render={() => <CommunityContainer {...this.props} />}
              />
              <Route
                key="1.19"
                path="/tour/:tourId"
                component={TourContainer}
              />
              {/* <Route path='/aboutMe' render={() => <AboutMe {...this.props} />} /> */}
              {/* <Route path='/login' render={() => <Login {...this.props} />} /> */}
              {/* <Route key="1.9" path='/login' render={() => <LoginContainer />} /> */}

              <Route
                key="1.12"
                path="/social"
                render={() => <SocialContainer {...this.props} />}
              />
              <Route
                key="1.13"
                path="/privacy"
                component={PrivacyAndTermsPage}
              />
              <Route
                key="1.14"
                path="/terms"
                component={PrivacyAndTermsPage}
              />
              <Route
                key="1.15"
                path="/admin"
                component={AdminContainer}
              />
              <Route
                key="1.16"
                path="/oauth/:platform/:action"
                component={OauthHandler}
              />
              <Route
                key="1.17"
                path="/fans/:socialmedia"
                component={FansHome}
              />

              <Route key="999" path="/home" component={HomePage} />
            </div>
          </AfterAuth>
        ) : (
          <div>
            <Switch>
              <Route
                key="2.1"
                exact
                path="/signup"
                component={SignUpContainer}
              />
              <Route
                  key="2.3"
                  path="/forgotPassword"
                  exact
                  component={ForgotPasswordContainer}
              />
              <Route
                  key="2.4"
                  path="/basic/setPassword"
                  exact
                  component={ResetPasswordContainer}
              />
              <Route
                  key="2.5"
                  path="/basic/allowToManage"
                  exact
                  component={AllowToManageContainer}
              />
              <Route key="2.2" component={LoginContainer} />
            </Switch>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    verifyToken: () => dispatch(verifyToken()),
    logoutUserAction: () => dispatch(logoutUserAction()),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BasicPage)
);

// <BeforeAuth>
//   { this.props.children }
//   <Footer />
//   <div style={{display: 'blocked', height: 80}}></div>
// </BeforeAuth>
