import { Avatar } from 'antd';

import './styles.css';

const BannerMobilePreview = ({ layout, imageUrl, title, description }) => {
  return (
    <div className='banner-container'>
      {layout === 'center' ? (
        <div className='image-wrapper-center'>
          <Avatar size={64} src={<img src={imageUrl} alt='avatar' />} />
          <div className='content-wrapper-center'>
            <p className='text-center'>{title}</p>
            <p className='text-center'>{description}</p>
          </div>
        </div>
      ) : (
        <>
          <div className='image-wrapper-left'>
            <Avatar size={64} src={<img src={imageUrl} alt='avatar' />} />
          </div>
          <div className='content-wrapper-left'>
            <p className='text-center'>{title}</p>
            <p className='text-center'>{description}</p>
          </div>
        </>
      )}
    </div>
  )
}

export default BannerMobilePreview;