import React, {Component} from 'react';

import {Step, Stepper, StepLabel} from 'material-ui/Stepper';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import ExpandTransition from 'material-ui/internal/ExpandTransition';
import Checkbox from 'material-ui/Checkbox';

import styles from '../../../style/profile';
import ArtistFavoriteGenre from './ArtistFavoriteGenre';
import ArtistLocation from './ArtistLocation';
import {states} from '../../../utils/constants';
import {getStateFromStateKey, getStateKeyFromState} from '../../../utils/utils';

class ProfileInit extends Component {

  state = {
    loading: false,
    finished: false,
    stepIndex: this.props.user.init,
    city: this.props.user.city,
    state: this.props.user.state,
    zip: this.props.user.zip,
    country: this.props.user.country,
    selectedGenre: this.props.user.genre,
    stateKey: getStateKeyFromState(states, this.props.user.state)
  };

  dummyAsync = (cb) => {
    this.setState({loading: true}, () => {
      this.asyncTimer = setTimeout(cb, 500);
    });
  };

  handleNext = () => {
    const {stepIndex} = this.state;
    const {neverShowAgain} = this.props;

    // handle step1 - favorite genre
    if (stepIndex === 0) {
      this.props.handleUpdateStep1(this.state.selectedGenre);
    }

    // handle step2 - location
    if (stepIndex === 1) {
      let {city, stateKey, zip} = this.state;
      let state = getStateFromStateKey(states, stateKey);
      this.props.handleUpdateStep2({city, state, zip});
    }

    if (neverShowAgain) {
      this.props.handleNeverShowAgain({}, true);
      this.props.handleClose();
    } else {
      if (!this.state.loading && stepIndex < 1) {
        this.dummyAsync(() => this.setState({
          loading: false,
          stepIndex: stepIndex + 1,
          finished: stepIndex >= 1,
        }));
      }
    }
  };

  handlePrev = () => {
    const {stepIndex} = this.state;
    if (!this.state.loading) {
      this.dummyAsync(() => this.setState({
        loading: false,
        stepIndex: stepIndex - 1,
      }));
    }
  };

  handleStateChange(event, key, payload) {
    this.setState({
      stateKey: key
    });
  }

  handleTextChange(event, newValue) {
    this.setState({
      [event.target.name]: newValue
    })
  }

  handleGenre(genre) {
    if (this.props.user.account === 'artist') {
      return [genre];
    } else {
      let {selectedGenre} = this.state;
      let newGens = selectedGenre.slice(0);
      if (selectedGenre.indexOf(genre) > -1) {
        // remove it from the selectedGenre
        newGens = selectedGenre.filter(e => e !== genre);
      } else {
        // add to selectedGenre
        newGens.push(genre);
      }
      return newGens;
    }
  }

  handleSelectGenre(event) {
    // console.log(genre.target.innerHTML)
    let genre = event.target.innerHTML;

    const newGens = this.handleGenre(genre);

    this.setState({
      selectedGenre: newGens
    });
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <ArtistFavoriteGenre
            selectedGenre={this.state.selectedGenre}
            handleSelectGenre={this.handleSelectGenre.bind(this)}
          />
        );
      case 1:
        return (
          <ArtistLocation
            handleStateChange={this.handleStateChange.bind(this)}
            handleTextChange={this.handleTextChange.bind(this)}
            stateKey={this.state.stateKey}
            city={this.state.city}
            zip={this.state.zip}
            states={states}
            country={this.state.country}
          />
        );
      default:
        return ' ';
    }
  }

  renderContent() {
    const {stepIndex} = this.state;
    const contentStyle = {margin: '0 16px', overflow: 'hidden'};

    return (
      <div style={contentStyle}>
        <div>{this.getStepContent(stepIndex)}</div>
        <div style={{marginTop: 24, marginBottom: 12}}>
          <FlatButton
            label="Back"
            disabled={stepIndex === 0}
            onClick={this.handlePrev}
            style={{marginRight: 12}}
          />
          <RaisedButton
            label={(stepIndex === 1 || this.props.neverShowAgain) ? 'Finish' : 'Next'}
            primary={true}
            onClick={this.handleNext}
          />
          <div style={{position: 'absolute', right: 10, display: 'inline-block', width: 250}}>
            <Checkbox
              label="Never show this again"
              onCheck={this.props.handleNeverShowAgain}
              checked={this.props.neverShowAgain}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {loading, stepIndex} = this.state;

    return (
      <div style={styles.getToKnowYouBetter}>
        <Stepper activeStep={stepIndex}>
          <Step>
            <StepLabel>Favorite Genre</StepLabel>
          </Step>
          <Step>
            <StepLabel>Location</StepLabel>
          </Step>
        </Stepper>
        <ExpandTransition loading={loading} open={true}>
          {this.renderContent()}
        </ExpandTransition>
      </div>
    )
  }
}

export default ProfileInit;