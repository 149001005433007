import React, {Component} from 'react';

import Chip from 'material-ui/Chip';
import {blue300} from 'material-ui/styles/colors';

import {genre} from '../../../utils/constants';

const styles = {
  chip: {
    margin: 4,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  }
};

class FavoriteGenre extends Component {

  state = {
  };

  isSelected = (genre) => {
    if (this.props.selectedGenre.indexOf(genre) > -1) {
      return blue300;
    }
    return '';
  };

  render() {
    return (
      <div>
        Select your favorite genres:
        <div style={styles.wrapper}>
          {
            genre.map((gen) => {
              return (
                <Chip
                  style={styles.chip}
                  backgroundColor={this.isSelected(gen)}
                  onClick={this.props.handleSelectGenre}
                  key={gen}
                >
                  {gen}
                </Chip>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default FavoriteGenre;