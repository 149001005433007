const styles = {
  getToKnowYouBetter: {
    width: '100%',
    maxWidth: 700,
    margin: 'auto'
  },

  profileItem: {
    display: 'flex',
    margin: 10,
    padding: 20,
    minHeight: 80,
    overflow: 'hidden'
  },


  // ********* old ****************
  // topSection: {
  //     height: 128,
  //     width: '100%',
  //     background: 'url(/img/sample/profile1.jpg) no-repeat center top fixed',
  //     backgroundSize: 'cover'
  // },
  // profilePicContainer: {
  //     width: 150,
  //     height: 150,
  //     position: 'absolute',
  //     top: 130,
  //     right: '50%',
  //     zIndex: 3,
  //     border: 'solid 6px #ffffff',
  //     boxShadow: '-2px 3px 2px rgba(0, 0, 0, 0.2)',
  //     overflow: 'hidden'
  // },
  // profilePic: {
  //     width: 150,
  //     height: 150
  // },
  // profileInfo: {
  //     backgroundColor: '#ced6e1',
  //     width: '100%',
  //     overflow: 'hidden',
  //     color: '#000000',
  //     paddingRight: 30,
  //     textAlign: 'right',
  //     minHeight: 75
  // },
  // content: {
  //     backgroundColor: '#000000',
  //     color: '#ffffff',
  //     textAlign: 'right'
  // },
  // followHeader: {
  //     height: 50,
  //     fontSize: '0.8em',
  //     margin: '10px auto',
  //     display: 'flex',
  //     alignItems: 'center'
  // },
  // followContent: {
  //     padding: '5px auto',
  //     borderBottom: 'solid 1px #ced6e1 ',
  //     backgroundColor: '#000000',
  //     overflow: 'hidden',
  //     height: 100
  // },
  // followSubHeader: {
  //     height: 50
  // },
  // followColumnHeader: {
  //     textAlign: 'right',
  //     paddingRight: 5,
  //     height: 100
  // },
  // followColumnContent: {
  //     position: 'absolute',
  //     bottom: 5,
  //     right: 5
  // },
  // followImg: {
  //     width: '100%',
  //     minWidth: 50,
  // },
  // followImgParent: {
  //     padding: 0
  // }
}

export default styles;