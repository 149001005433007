import React from 'react';

export const Pagination = ({ children }) => {
  return <nav className="flex justify-center mt-6">{children}</nav>;
};

export const PaginationContent = ({ children }) => {
  return <div className="flex items-center space-x-2">{children}</div>;
};

export const PaginationItem = ({ children }) => {
  return <div>{children}</div>;
};

export const PaginationLink = ({ onClick, className = '', children }) => {
  return (
    <button
      onClick={onClick}
      className={`px-3 py-2 rounded-md ${className}`}
    >
      {children}
    </button>
  );
};

export const PaginationPrevious = ({ onClick, className = '', disabled }) => {
  return (
    <PaginationLink
      onClick={onClick}
      className={`${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      Previous
    </PaginationLink>
  );
};

export const PaginationNext = ({ onClick, className = '', disabled }) => {
  return (
    <PaginationLink
      onClick={onClick}
      className={`${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    >
      Next
    </PaginationLink>
  );
};