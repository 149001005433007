import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';

import { callApi } from '../utils/utils';
import history from '../redux/manageHistory';

const styles = {
  allowToManage: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    textAlign: 'center'
  },
  mainText: {
    color: '#cccccc',
    marginBottom: 20,
    fontSize: '1.5em'
  },
  loading: {
    color: '#888888',
    marginBottom: 20,
    fontSize: '1.5em'
  },
  pageTitle: {
    color: '#cccccc',
    marginBottom: 50,
    textAlign: 'center'
  },
  error: {
    color: '#ff0000',
    marginBottom: 20,
    fontSize: '1.5em'
  }
};

const AllowToManageContainer = props => {

  const [ loading, setLoading ] = useState(true);
  const [ manager, setManager ] = useState('');
  const [ error, setError ] = useState('');

  const queryParameters = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (queryParameters) {
      const code = queryParameters.get("code");
      console.log('---code:', code);

      verifyEmailCode(code);
    }
  }, []);

  const verifyEmailCode = async(code) => {
    const verified = await callApi('/api/v1/verifyManageInviteEmail', 'post', {
      code
    });

    const { data } = verified;

    console.log( '----data---:', data);

    if (data.status === 'successful') {
      setManager(data.managerName);
      setLoading(false);
    } else {
      setLoading(false);
      setError(data.errorMessage)
    }
  };

  const backToHome = _ => {
    setLoading(true);
    setManager('');
    setError('');
    history.push('/');
  };

  return (
    <Row style={styles.allowToManage}>
      <Col xs={{ span: 8 }}>
        { loading && (
          <div style={styles.loading}>>Processing your request ...</div>
        )}

        { !loading && (
          <>
            { error && (
              <div style={styles.error}>{error}</div>
            )}

            { !error && (
              <div style={styles.mainText}>Your account has been set to be managed by <strong>{ manager }</strong></div>
            )}
            
            <Button variant="secondary" onClick={backToHome}>
              Return to Home
            </Button>
          </>
        )}
      </Col>
    </Row>
  )
};

export default AllowToManageContainer;
