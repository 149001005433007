import React from 'react';
import { Avatar, AvatarImage, AvatarFallback } from '../../ui/avatar';

export const ArtistCard = ({ artist, onClick }) => {
  return (
    <div
      key={artist.id || artist._id}
      className="block group cursor-pointer"
      onClick={() => onClick(artist)}
    >
      <div className="relative bg-gradient-to-br from-gray-900 to-gray-800 rounded-lg p-6 transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
        <div className="absolute inset-0 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 opacity-0 group-hover:opacity-20 rounded-lg transition-opacity duration-300"></div>
        <div className="absolute inset-0 border-2 border-transparent group-hover:border-purple-500 rounded-lg transition-all duration-300 ease-in-out"></div>
        <div className="flex items-center space-x-4 relative z-10">
          <Avatar className="h-16 w-16 ring-2 ring-purple-500 ring-offset-2 ring-offset-gray-900">
            <AvatarImage src={artist.avatarUrl || '/placeholder.svg'} alt={artist.name} />
            <AvatarFallback>{artist.name.charAt(0)}</AvatarFallback>
          </Avatar>
          <div>
            <h2 className="text-gray-100 text-xl font-medium group-hover:text-purple-400 transition-colors">
              {artist.name}
            </h2>
            <p className="text-gray-400 text-sm group-hover:text-purple-300 transition-colors">
              View Profile
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};