    import React, { useState } from 'react';

    // Predefined time slots
    const TIME_SLOTS = [
    '08:00', '09:00', '10:00', '11:00', 
    '12:00', '13:00', '14:00', '15:00', 
    '16:00', '17:00', '18:00', '19:00'
    ];

    
    const AvailabilityCalendar = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [availableDates, setAvailableDates] = useState({});
    const [selectedTimeSlots, setSelectedTimeSlots] = useState({});

    // Refined cyberpunk color palette
    const colors = {
        background: '#0d1117', // Deep dark navy
        text: '#7d8590', // Muted gray-blue
        accent: 'radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)', // Dark slate
        borderAccent: 'rgba(252,70,107,1)',
        highlight: 'radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%)', // Slightly lighter background
        border: '#30363d', // Soft dark border
        activeText: '#e6edf3' // Soft light text
    };

    // Existing month generation logic remains the same
    const getDaysInMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const days = [];

        const startingDay = firstDay.getDay();
        for (let i = 0; i < startingDay; i++) {
        days.push(null);
        }

        for (let day = 1; day <= lastDay.getDate(); day++) {
        days.push(new Date(year, month, day));
        }

        return days;
    };

    // Toggle date availability (logic remains the same)
    const toggleDateAvailability = (date) => {
        const dateString = date.toISOString().split('T')[0];
        setAvailableDates(prev => {
        const newAvailableDates = {...prev};
        if (newAvailableDates[dateString]) {
            delete newAvailableDates[dateString];
        } else {
            newAvailableDates[dateString] = true;
        }
        return newAvailableDates;
        });

        // Remove time slots if date is no longer available
        if (selectedTimeSlots[dateString]) {
        const newTimeSlots = {...selectedTimeSlots};
        delete newTimeSlots[dateString];
        setSelectedTimeSlots(newTimeSlots);
        }
    };

    // Toggle time slot selection (logic remains the same)
    const toggleTimeSlot = (date, timeSlot) => {
        const dateString = date.toISOString().split('T')[0];
        
        // Ensure the date is marked as available first
        if (!availableDates[dateString]) {
        toggleDateAvailability(date);
        }

        setSelectedTimeSlots(prev => {
        const newTimeSlots = {...prev};
        
        if (!newTimeSlots[dateString]) {
            newTimeSlots[dateString] = {};
        }

        if (newTimeSlots[dateString][timeSlot]) {
            delete newTimeSlots[dateString][timeSlot];
            
            if (Object.keys(newTimeSlots[dateString]).length === 0) {
            delete newTimeSlots[dateString];
            }
        } else {
            newTimeSlots[dateString][timeSlot] = true;
        }

        return newTimeSlots;
        });
    };

    // Navigate months
    const goToPreviousMonth = () => {
        setCurrentDate(prev => new Date(prev.getFullYear(), prev.getMonth() - 1, 1));
    };

    const goToNextMonth = () => {
        setCurrentDate(prev => new Date(prev.getFullYear(), prev.getMonth() + 1, 1));
    };

    const days = getDaysInMonth(currentDate);

    const styles = {
        calendar: {
        fontFamily: "'Inter', sans-serif",
        maxWidth: '700px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: colors.background,
        borderRadius: '8px',
        border: `1px solid ${colors.border}`,
        color: colors.text
        },
        navigation: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
        paddingBottom: '10px',
        borderBottom: `1px solid ${colors.border}`
        },
        navButton: {
        background: 'transparent',
        color: colors.text,
        border: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        padding: '5px 10px',
        borderRadius: '4px',
        transition: 'color 0.3s ease'
        },
        monthTitle: {
        fontSize: '1.2em',
        fontWeight: '600',
        color: colors.activeText
        },
        calendarGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: '8px',
        textAlign: 'center'
        },
        dayHeader: {
        color: colors.text,
        fontWeight: '500',
        textTransform: 'uppercase',
        fontSize: '0.8em'
        },
        day: {
        padding: '10px',
        borderRadius: '4px',
        cursor: 'pointer',
        border: `1px solid ${colors.border}`,
        transition: 'all 0.3s ease',
        position: 'relative',
        fontSize: '0.9em'
        },
        unavailableDay: {
        backgroundColor: 'transparent',
        color: colors.text
        },
        availableDay: {
        background: colors.highlight,
        color: colors.activeText,
        fontWeight: '600'
        },
        timeSlotContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
        marginTop: '15px'
        },
        timeSlot: {
        padding: '5px 10px',
        border: `1px solid ${colors.border}`,
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '0.8em',
        transition: 'all 0.3s ease',
        color: colors.text
        },
        selectedTimeSlot: {
        background: colors.accent,
        color: colors.activeText,
        border: `1px solid ${colors.text}`
        },
        summaryContainer: {
        marginTop: '20px',
        padding: '15px',
        background: colors.highlight,
        borderRadius: '4px'
        }
    };

    return (
        <div style={styles.calendar}>
        {/* Navigation */}
        <div style={styles.navigation}>
            <button 
            onClick={goToPreviousMonth} 
            style={styles.navButton}
            onMouseOver={(e) => e.target.style.color = colors.activeText}
            onMouseOut={(e) => e.target.style.color = colors.text}
            >
            ← Previous
            </button>
            <div style={styles.monthTitle}>
            {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
            </div>
            <button 
            onClick={goToNextMonth} 
            style={styles.navButton}
            onMouseOver={(e) => e.target.style.color = colors.activeText}
            onMouseOut={(e) => e.target.style.color = colors.text}
            >
            Next →
            </button>
        </div>

        {/* Calendar Grid */}
        <div style={styles.calendarGrid}>
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
            <div key={day} style={styles.dayHeader}>{day}</div>
            ))}
            {days.map((date, index) => {
            if (!date) return <div key={index}></div>;

            const dateString = date.toISOString().split('T')[0];
            const isAvailable = availableDates[dateString];

            return (
                <div 
                key={index}
                style={{
                    ...styles.day,
                    ...(isAvailable ? styles.availableDay : styles.unavailableDay)
                }}
                onClick={() => toggleDateAvailability(date)}
                >
                {date.getDate()}
                {isAvailable && (
                    <div style={{
                    position: 'absolute', 
                    top: '3px', 
                    right: '3px', 
                    color: colors.text,
                    fontSize: '10px'
                    }}>
                    •
                    </div>
                )}
                </div>
            );
            })}
        </div>

        {/* Time Slots */}
        {Object.keys(availableDates).length > 0 && (
            <div>
            <h3 style={{
                color: colors.activeText, 
                marginTop: '20px', 
                marginBottom: '10px',
                borderBottom: `1px solid ${colors.border}`,
                paddingBottom: '10px'
            }}>
                Select Time Slots
            </h3>
            {Object.keys(availableDates).map(dateString => {
                const date = new Date(dateString);
                return (
                <div key={dateString}>
                    <div style={{color: colors.activeText, marginBottom: '10px', marginTop: '10px'}}>
                    {date.toLocaleDateString()}
                    </div>
                    <div style={styles.timeSlotContainer}>
                    {TIME_SLOTS.map(timeSlot => {
                        const isSelected = selectedTimeSlots[dateString]?.[timeSlot];
                        return (
                        <div
                            key={timeSlot}
                            style={{
                            ...styles.timeSlot,
                            ...(isSelected ? styles.selectedTimeSlot : {})
                            }}
                            onClick={() => toggleTimeSlot(date, timeSlot)}
                        >
                            {timeSlot}
                        </div>
                        );
                    })}
                    </div>
                </div>
                );
            })}
            </div>
        )}

        {/* Availability Summary */}
        <div style={styles.summaryContainer}>
            <h3 style={{
            color: colors.activeText, 
            marginBottom: '10px',
            borderBottom: `1px solid ${colors.border}`,
            paddingBottom: '10px'
            }}>
            Availability Summary
            </h3>
            {Object.entries(selectedTimeSlots).length === 0 ? (
            <p style={{color: colors.text}}>No time slots selected</p>
            ) : (
            Object.entries(selectedTimeSlots).map(([date, slots]) => (
                <div 
                key={date} 
                style={{
                    marginBottom: '5px', 
                    color: colors.activeText,
                    paddingLeft: '10px',
                    borderLeft: `3px solid ${colors.borderAccent}`
                }}
                >
                <strong>{date}:</strong>{' '}
                {Object.keys(slots).join(', ')}
                </div>
            ))
            )}
        </div>
        </div>
    );
    };

    export default AvailabilityCalendar;