import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import TextField from 'material-ui/TextField';
import Switch from '@mui/material/Switch';
import { callApi } from '../../../utils/utils';

const SignupStepSelectArtist = props => {
  const { selectedGoals, selectGoal, goalError } = props;
  const [ manageArtist, setManageArtist ] = useState(false);
  const [ searching, setSearching ] = useState(false);
  const [ searchingArtistName, setSearchingArtistName ] = useState('');
  const [ foundArtists, setFoundArtist ] = useState([]);
  const [ sendInviteButtonText, setSendInviteButtonText ] = useState('Send Invite');

  const styles = {
    selectGoal: {
      fontSize: '150%'
    },
    result: {
      color: 'yellow',
      marginTop: 10,
      fontSize: '1.2em'
    },
    profilePic: {
      height: '2rem',
      width: '2rem',
      objectFit: 'cover',
      borderRadius: '50%',
      border: '2px solid #fff',
      marginRight: 10
    },
    inviteButton: {
      marginLeft: 15
    }
  }

  const setYes = event => {
    setManageArtist(event.target.checked);
  }

  const searchForArtist = event => {
    setSearchingArtistName(event.target.value);
    if (!searching && event.target.value.length > 2) {
      setSearching(true);
      queryForArtist(event.target.value)
    }
  }

  const queryForArtist = async (name) => {
    try {
      const response = await callApi(`/api/v1/artists?name=${name}`, 'GET', {}, {});
      const artists = response.data.artists;

      if (artists) {
        const mappedArtists = artists.map(artist => {
          return {
            name: artist.name,
            knktId: artist.knktId,
            profileImage: artist.profileImage
          }
        })
        setFoundArtist(mappedArtists);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSearching(false);
    }
  }

  const sendInviteEmail = async (e, knktId) => {
    // console.log('=====knktId', knktId)
    try {
      const response = await callApi(`/api/v1/sendManageInviteEmail?knktId=${knktId}`, 'POST', {}, {});

      // console.log('-----response:', response);

      if (response && response.data && response.data.status === 'successful') {
        // setSendInviteButtonText('Sent!');
        e.target.innerHTML = 'Sent!';
        e.target.disabled = true;
      } else {
        setSendInviteButtonText('Error!');
      }

    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Row>
      <Col xs={12} style={styles.selectGoal}>
        Do you manage any artist? <Switch inputProps={{ 'aria-label': 'Switch demo' }} onChange={setYes} />
      </Col>
      <Col xs={12}>
        
      </Col>
      {
        manageArtist && (
          <Col xs={12}>
            <TextField
              id='artistName'
              floatingLabelText='Enter Artist Name'
              onChange={searchForArtist.bind(this)}
            />
          </Col>
        )
      }
      
      {
        foundArtists && foundArtists.length > 0 && foundArtists.map(artist => {
          return (
            <Col key={artist.name} xs={12} style={styles.result}>
              <img
                style={styles.profilePic}
                src={artist.profileImage || '/img/logo/knkt-logo.svg'}
                alt={artist.name}
                className="profile-pic"
              />
              { artist.name }
              <Button 
                style={styles.inviteButton}
                onClick={(e) => sendInviteEmail(e, artist.knktId)}
              >
                { sendInviteButtonText }
              </Button>
            </Col>
          )
        })
      }
    </Row>
  )
}

export default SignupStepSelectArtist;