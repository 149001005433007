import React, {Component} from 'react';
import {Col} from 'react-bootstrap';
import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import FacebookProvider, {Share, Like, Comments, Page} from 'react-facebook';

import Paper from 'material-ui/Paper';

import styles from '../style/profile';
import UpComingCalendarContainer from './UpComingCalendarContainer';
import StudioCalendarContainer from './StudioCalendarContainer';
import BuzzContainer from './BuzzContainer';
import {getFBAppId} from '../config/siteConfig';

class ArtistContainer extends Component {
  render() {
    return (
      <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme)}>
        <div>
          <Col sm={6} xs={12}>
            <Paper style={styles.profileItem} zDepth={3}>
              <UpComingCalendarContainer />
            </Paper>
          </Col>
          <Col sm={6} xs={12}>
            <Paper style={styles.profileItem} zDepth={3} className="wow bounceIn">
              <StudioCalendarContainer />
            </Paper>
          </Col>
          <Col sm={6} xs={12}>
            <Paper style={styles.profileItem} zDepth={3} className="wow rubberBand">
              <BuzzContainer buzz="internal"/>
            </Paper>
          </Col>
          <Col sm={6} xs={12}>
            <Paper style={styles.profileItem} zDepth={3} className="wow shake">
              <BuzzContainer buzz="external"/>
            </Paper>
          </Col>

          <Col sm={6} xs={12}>
            <Paper style={styles.profileItem} zDepth={3}>
              Nothing here!
            </Paper>
          </Col>

          <Col sm={6} xs={12}>
            <Paper style={styles.profileItem} zDepth={3}>
              <div
                className="fb-login-button"
                data-max-rows="1"
                data-size="small"
                data-button-type="login_with"
                data-show-faces="false"
                data-auto-logout-link="false"
                data-use-continue-as="false"
                scope="email,public_profile,user_friends,publish_actions,manage_pages,user_photos,user_posts,user_videos,user_events,manage_pages,user_birthday"
              >
              </div>
            </Paper>
          </Col>

          <Col sm={6} xs={12}>
            <Paper style={styles.profileItem} zDepth={3}>
              <FacebookProvider appId={getFBAppId()}>
                <Like href="https://www.facebook.com/karpediemmusic/" colorScheme="dark" showFaces share/>
              </FacebookProvider>

            </Paper>
          </Col>

          <Col sm={6} xs={12}>
            <Paper style={styles.profileItem} zDepth={3}>
              <FacebookProvider appId={getFBAppId()}>
                <Share href="https://www.facebook.com/karpediemmusic/">
                  <button type="button">Share</button>
                </Share>
              </FacebookProvider>
            </Paper>
          </Col>

          <Col sm={6} xs={12}>
            <Paper style={styles.profileItem} zDepth={3}>
              <FacebookProvider appId={getFBAppId()}>
                <Comments href="https://www.facebook.com/karpediemmusic/"/>
              </FacebookProvider>
            </Paper>
          </Col>

          <Col sm={6} xs={12}>
            <Paper style={styles.profileItem} zDepth={3}>
              <FacebookProvider appId={getFBAppId()}>
                <Page href="https://www.facebook.com/karpediemmusic/" tabs="timeline"/>
              </FacebookProvider>
            </Paper>
          </Col>
        </div>
      </MuiThemeProvider>
    )
  }
}

export default ArtistContainer;