import React, { useState } from 'react';
import { Button, List, Avatar, Tooltip } from 'antd';
import AddLinkModal from '../components/modals/AddLinkModal/AddLinkModal';
import { PlusCircleOutlined, FileTextOutlined, CalendarOutlined } from '@ant-design/icons';
import AddTextModal from '../components/modals/AddTextModal/AddTextModal';
import AddContactFormModal from '../components/modals/AddContactFormModal/AddContactFormModal';
import AddEventModal from '../components/modals/AddEventModal/AddEventModal';

import './createLinkContainer.css';
import ProfileMobilePreview from '../components/mobile/ProfileMobilePreview/ProfileMobilePreview';

const styles = {
  card: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center'
  },
  listContainer: {
    backgroundColor: 'rgb(251, 215, 43)',
    marginTop: '20px',
    padding: '10px'
  },
  listItemMeta: {
    display: 'flex',
    alignItems: 'center'
  },
};

// This component has lots of duplicated code, but it can be enhanced later when we are sure about the structure and design
const CreateLinkContainer = ({ user }) => {
  const [showAddLinkModal, setShowAddLinkModal] = useState(false);
  const [showAddTextModal, setShowAddTextModal] = useState(false);
  const [showAddContactFormModal, setShowAddContactFormModal] = useState(false);
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [links, setlinks] = useState([]);
  const [texts, setText] = useState([]);
  const [events, setEvents] = useState([]);
  const [contactForms, setContactForms] = useState([]);

  const handleSaveLink = (link) => {
    setlinks((prevLinks) => [...prevLinks, link])
    setShowAddLinkModal(false)
  }

  const handleSaveText = (text) => {
    setText((prevTexts) => [...prevTexts, text])
    setShowAddTextModal(false)
  }

  const handleSaveEvent = (event) => {
    setEvents((prevEvent) => [...prevEvent, event])
    setShowAddEventModal(false)
  }

  const handleSaveContactForm = (contactForm) => {
    setContactForms((prevContactForms) => [...prevContactForms, contactForm])
    setShowAddContactFormModal(false)
  }

  return (
    <div className='create-link-container'>
      <div className='left-side'>
        <AddLinkModal
          isOpen={showAddLinkModal}
          handleSave={handleSaveLink}
          handleCancel={() => setShowAddLinkModal(false)}
        />

        <AddTextModal
          isOpen={showAddTextModal}
          handleSave={handleSaveText}
          handleCancel={() => setShowAddTextModal(false)}
        />

        <AddContactFormModal
          isOpen={showAddContactFormModal}
          handleSave={handleSaveContactForm}
          handleCancel={() => setShowAddContactFormModal(false)}
        />

        <AddEventModal
          isOpen={showAddEventModal}
          handleSave={handleSaveEvent}
          handleCancel={() => setShowAddEventModal(false)}
        />
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-12' style={styles.card}>
              <Button
                type='primary'
                icon={<PlusCircleOutlined />}
                iconPosition={'start'}
                onClick={() => setShowAddLinkModal(true)}
              >
                New Link
              </Button>
            </div>
            <div className='col-sm-12' style={styles.card}>
              <Button
                type='primary'
                icon={<PlusCircleOutlined />}
                iconPosition={'start'}
                onClick={() => setShowAddTextModal(true)}
              >
                New Text
              </Button>
            </div>
            <div className='col-sm-12' style={styles.card}>
              <Button
                type='primary'
                icon={<PlusCircleOutlined />}
                iconPosition={'start'}
                onClick={() => setShowAddContactFormModal(true)}
              >
                New Contact Form
              </Button>
            </div>
            <div className='col-sm-12' style={styles.card}>
              <Button
                type='primary'
                icon={<PlusCircleOutlined />}
                iconPosition={'start'}
                onClick={() => setShowAddEventModal(true)}
              >
                New Event
              </Button>
            </div>
          </div>

          {/* Links Container */}
          {links.length ? (
            <div className='row'>
              <div className='col-sm-12'>
                <List
                  bordered
                  header={<h5>Links</h5>}
                  pagination={{ position: 'bottom', align: 'center', pageSize: 4, }}
                  dataSource={links}
                  renderItem={(link) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={link.imageUrl} shape='square' size={64} />}
                        title={<a href={link.url} target='_blank'>{link.title}</a>}
                        style={styles.listItemMeta}
                      />
                    </List.Item>
                  )}
                  style={styles.listContainer}
                  />
              </div>
            </div>
          ) : null}

          {/* Textareas Container */}
          {texts.length ? (
            <div className='row'>
              <div className='col-sm-12'>
                <List
                  bordered
                  header={<h5>Textareas</h5>}
                  pagination={{ position: 'bottom', align: 'center', pageSize: 3, }}
                  dataSource={texts}
                  renderItem={(text) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar icon={<FileTextOutlined />} shape='square' size={64} />}
                        title={
                          <>
                            {text.length > 30 ? (
                              <Tooltip title={text}>
                                <span>{`${text.slice(0, 30)}...`}</span>
                              </Tooltip>
                            ) : text}
                          </>
                        }
                        style={styles.listItemMeta}
                      />
                    </List.Item>
                  )}
                  style={styles.listContainer}
                  />
              </div>
            </div>
          ) : null}

          {/* Events Container */}
          {events.length ? (
            <div className='row'>
              <div className='col-sm-12'>
                <List
                  bordered
                  header={<h5>Events</h5>}
                  pagination={{ position: 'bottom', align: 'center', pageSize: 3, }}
                  dataSource={events}
                  renderItem={(event) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar icon={<CalendarOutlined />} shape='square' size={64} />}
                        title={event.venueName}
                        description={event.location}
                        style={styles.listItemMeta}
                      />
                    </List.Item>
                  )}
                  style={styles.listContainer}
                  />
              </div>
            </div>
          ) : null}

          {/* Contact Form Container */}
          {contactForms.length ? (
            <div className='row'>
              <div className='col-sm-12'>
                <List
                  bordered
                  header={<h5>Contact Forms</h5>}
                  pagination={{ position: 'bottom', align: 'center', pageSize: 4, }}
                  dataSource={contactForms}
                  renderItem={(contactForm) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar src={contactForm.imageUrl} shape='square' size={64} />}
                        title={contactForm.title}
                        description={contactForm.description}
                        style={styles.listItemMeta}
                      />
                    </List.Item>
                  )}
                  style={styles.listContainer}
                  />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="mobile-preview">
        <ProfileMobilePreview user={user} contactForms={contactForms} />
      </div>
    </div>
  )
}

export default CreateLinkContainer;
