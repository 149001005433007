import React, {Component} from 'react';
import PlacesAutocomplete from 'react-places-autocomplete'

import SelectField from 'material-ui/SelectField';
import TextField from 'material-ui/TextField';
import MenuItem from 'material-ui/MenuItem';

import {states} from '../../../utils/constants';
import {getStateKeyFromState} from '../../../utils/utils';

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      isError: false
    };
    this.onChange = this.onChange.bind(this);
    this.handleOnError = this.handleOnError.bind(this);
  }

  onChange(address) {
    // console.log(address);
    this.setState({address});
    const addr = address.split(',');

    let event = {};
    event.target = {};

    if (addr.length >= 3) {
      const country = addr[(addr.length - 1)];
      const stateZip = addr[(addr.length - 2)].trim();
      const city = addr[(addr.length - 3)];

      if (stateZip.length > 2 && stateZip.trim().indexOf(' ') > 0) {
        const state = stateZip.substr(0, 2);
        const zip = stateZip.substring(stateZip.indexOf(' '));

        event.target.name = 'state';
        this.props.handleStateChange(event, getStateKeyFromState(states, state.trim()));

        event.target.name = 'zip';
        this.props.handleTextChange(event, zip.trim());
      } else {
        event.target.name = 'state';
        this.props.handleStateChange(event, this.getStateKeyFromState(states, stateZip));

        event.target.name = 'zip';
        this.props.handleTextChange(event, '');
      }

      event.target.name = 'country';
      this.props.handleTextChange(event, country.trim());

      event.target.name = 'city';
      this.props.handleTextChange(event, city.trim());
    } else {
      event.target.name = 'state';
      this.props.handleStateChange(event, '');

      event.target.name = 'zip';
      this.props.handleTextChange(event, '');

      event.target.name = 'country';
      this.props.handleTextChange(event, '');

      event.target.name = 'city';
      this.props.handleTextChange(event, '');
    }
  }

  handleOnError() {
    this.setState({
      isError: true
    })
  }

  render() {
    const inputProps = {
      value: this.state.address,
      onChange: this.onChange,
      type: 'cities',
      placeholder: 'Enter Address here...',
      autoFocus: true
    };

    return (
      <div>
        Your locations:
        <div>
          <PlacesAutocomplete
            inputProps={inputProps}
            highlightFirstSuggestion={true}
            onError={this.handleOnError}
          />
          <br />
          <TextField
            hintText="City"
            name="city"
            onChange={this.props.handleTextChange}
            value={this.props.city}
            disabled={!this.state.isError}
          />
          <br />
          <SelectField
            floatingLabelText="State"
            value={this.props.stateKey}
            onChange={this.props.handleStateChange}
            disabled={!this.state.isError}
          >
            {
              Object.keys(this.props.states).map((key, index) => {
                return (
                  <MenuItem key={key} value={index} primaryText={this.props.states[key]}/>
                )
              })
            }
          </SelectField>
          <br />
          <TextField
            hintText="Postal Code"
            name="zip"
            onChange={this.props.handleTextChange}
            value={this.props.zip}
            disabled={!this.state.isError && this.props.zip.length>0}
            errorText={(this.props.country && this.props.zip.length<1)?'Please enter your Postal Code':''}
          />
          <br />
          <TextField
            hintText="Country"
            name="country"
            onChange={this.props.handleTextChange}
            value={this.props.country}
            disabled={!this.state.isError}
            errorText={(this.props.country && this.props.country !== 'United States')?'Please enter a US address':''}
          />
        </div>
      </div>
    )
  }
}

export default Location;