import React, { useState } from 'react';
import { Button, Modal, Input, Checkbox, Upload } from 'antd';
import { PicCenterOutlined, PicLeftOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import './styles.css';
import { checkImageFormatAndSize, getBase64, validateEmail } from '../../../utils/utils';
import BannerPreview from '../../mobile/BannerMobilePreview/BannerMobilePreview';

const AddContactFormModal = ({ isOpen, handleSave, handleCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    city: '',
    isCommunicationAccepted: false,
  });
  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState({
    layout: 'center',
    imageUrl: '',
    title: 'Title',
    description: 'Description'
  });

  const handleSaveContactForm = () => {
    handleSave(bannerData);
    setFormData({})
  }

  const customRequest = ({ file, onSuccess }) => {
    getBase64(file, (url) => {
      setLoading(false);
      setBannerData((prevData) => ({ ...prevData, url }))
      setBannerData((prevData) => ({ ...prevData, imageUrl: url}))
      onSuccess('ok');
    });
  };
  
  const handleUploadPhoto = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
  };

  const uploadButton = (
    <button className='upload-button' type='button'>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className='upload-text'>
        Add Image
      </div>
    </button>
  );

  return (
    <Modal
      open={isOpen}
      title='Create a Contact Form'
      onCancel={handleCancel}
      footer={[
        <Button key='back' onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key='submit' type='primary' onClick={handleSaveContactForm}>
          Next
        </Button>
      ]}
      maskClosable={false}
      className='contact-form-modal'
    >
      <div className="form-modal-container">
        <div className="fields-section">

          <div className="layout-container">
            <h5>Layout</h5>
            <div className="icons-wrapper">
              <PicLeftOutlined onClick={() => setBannerData((prevData) => ({ ...prevData, layout: 'left' }))} />
              <PicCenterOutlined onClick={() => setBannerData((prevData) => ({ ...prevData, layout: 'center' }))} />
            </div>
          </div>

          <div className="image-container">
            <h5>Image</h5>
            <Upload
              name='avatar'
              listType='picture-card'
              className='avatar-uploader'
              showUploadList={false}
              customRequest={customRequest}
              beforeUpload={checkImageFormatAndSize}
              onChange={handleUploadPhoto}
            >
              {bannerData.imageUrl ? (
                <img
                  src={bannerData.imageUrl}
                  alt='avatar'
                  className='link-picture'
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </div>

          <div className="content-container">
            <label htmlFor='title-input'>Title</label>
            <Input
              className='input'
              id='title-input'
              placeholder='Add a title'
              value={bannerData.title}
              size='large'
              onChange={(e) => setBannerData((prevData) => ({ ...prevData, title: e.target.value }))}
            />

            <label htmlFor='description-input'>Description</label>
            <Input
              className='input'
              id='description-input'
              placeholder='Add a description'
              value={bannerData.description}
              size='large'
              onChange={(e) => setBannerData((prevData) => ({ ...prevData, description: e.target.value }))}
            />
          </div>

          {/* should be move somewhere else (this should be shown once we click on contact form in mobile preview) */}
          {/* <label htmlFor='name-input'>Name</label>
          <Input
            className={`${errors.name ? 'input-error' : ''}`}
            id='name-input'
            placeholder='Add a name'
            value={formData.name}
            size='large'
            onChange={(event) => handleChangeInput('name', event.target.value)}
            maxLength={35}
          />
          <p className='error-message'>{errors.name}</p>

          <label htmlFor='email-input'>E-mail</label>
          <Input
            className={`${errors.email ? 'input-error' : ''}`}
            id='email-input'
            placeholder='Add an e-mail'
            value={formData.email}
            size='large'
            onChange={(event) => handleChangeInput('email', event.target.value)}
            maxLength={35}
          />
          <p className='error-message'>{errors.email}</p>

          <label htmlFor='phone-number-input'>Phone Number</label>
          <Input
            className={`${errors.phoneNumber ? 'input-error' : ''}`}
            id='phone-number-input'
            placeholder='Add a phone nummber'
            value={formData.phoneNumber}
            size='large'
            onChange={(event) => handleChangeInput('phoneNumber', event.target.value)}
            maxLength={35}
          />
          <p className='error-message'>{errors.phoneNumber}</p>

          <label htmlFor='city-input'>City</label>
          <Input
            className={`${errors.city ? 'input-error' : ''}`}
            id='city-input'
            placeholder='Add a city'
            value={formData.city}
            size='large'
            onChange={(event) => handleChangeInput('city', event.target.value)}
            maxLength={35}
          />
          <p className='error-message'>{errors.city}</p>

          <Checkbox onChange={(event) => handleChangeInput('isCommunicationAccepted', event.target.checked)}>
            I accept to receive communication from KNKT.
          </Checkbox> */}
        </div>
        <div className="mobile-preview-section">
          <BannerPreview {...bannerData} />
        </div>
      </div>
    </Modal>
  )
}

export default AddContactFormModal;
