import React from 'react';
import { Avatar } from 'antd';
import {
  UserOutlined,
  FacebookFilled,
  InstagramFilled,
  YoutubeFilled,
  SoundFilled
} from '@ant-design/icons';


import BannerMobilePreview from '../BannerMobilePreview/BannerMobilePreview';
import './styles.css';

// should save avatar and username (probably more data) to user results - currently avatar = null & username = ""
const ProfileMobilePreview = ({ user, contactForms }) => {
  console.log('contactForms', contactForms)
  const showProfileIcons = () => {
    const icons = [];
    Object.entries(user.connectedProfiles).forEach(([key, value]) => {
      if (value.id) {
        switch (key) {
          case 'facebook':
            icons.push(<FacebookFilled />)
            break;
            
          case 'instagram':
            icons.push(<InstagramFilled />)
            break;

          case 'soundCloud':
            icons.push(<SoundFilled onClick={() => window.open(value.soundCloudUrl, "_blank")} />)
            break;

          case 'youtube':
            icons.push(<YoutubeFilled />) // TODO add onClick function for all profiles when data is provided
            break;

          default:
            return null;
        }
      }
    });
    return icons;
  }
  return (
    <>
      <div className='profile-container'>
        <div className='avatar-container'>
          <Avatar className='avatar' shape="square" size={120} icon={<UserOutlined />} />
          <h3>{user.firstName}</h3>
        </div>

        <div className='icons-container'>
          {showProfileIcons()}
        </div>

        <div className="contact-forms">
          {contactForms?.map((contactForm) => {
            return (
              <div className="mobile-wrapper">
                <h4 className='module-title'>Contact Form</h4>
                <BannerMobilePreview {...contactForm} />
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
};

export default ProfileMobilePreview;
