import React, {Component} from 'react';

class BuzzContainer extends Component {
  constructor(props) {
    super(props);
    this.getTitle = this.getTitle.bind(this);
    this.callBuzz = this.callBuzz.bind(this);
    this.BuzzContainer = this.buildBuzz.bind(this);
    this.normalizeBuzz = this.normalizeBuzz.bind(this);
  }

  getTitle() {
    switch (this.props.buzz) {
      case 'internal':
      {
        return 'Buzz - Internal';
      }
      case 'external':
      {
        return 'Buzz - External';
      }
      default:
      {
        return 'Buzz';
      }
    }
  }

  callBuzz() {
    return [
      {name: 'word', count: 5},
      {name: 'word', count: 7},
      {name: 'word', count: 10},
      {name: 'word', count: 5},
      {name: 'word', count: 12},
      {name: 'word', count: 5},
      {name: 'word', count: 8},
      {name: 'word', count: 4}
    ]
  }

  normalizeBuzz() {
    const buzzes = this.callBuzz();
    let large = 0;
    buzzes.map((buzz) => {
      let count = buzz.count;
      return large = count > large ? count : large;
    })
    return large;
  }

  buildBuzz() {
    const factor = 3 / this.normalizeBuzz();
    const buzzes = this.callBuzz();
    return buzzes.map((buzz, index) => {
      return (
        <span key={'buzz'+index} style={{padding: 5, fontSize: (buzz.count * factor)+'em'}}>{buzz.name}</span>
      )
    })
  }

  render() {
    return (
      <div style={{width: '100%', wordBreak: 'break-word'}}>
        <div>{this.getTitle()}</div>
        <div>{this.buildBuzz()}</div>
      </div>
    )
  }
}

export default BuzzContainer;